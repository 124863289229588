import * as React from 'react';
import { graphql } from 'gatsby';
import { BuilderComponent } from '@builder.io/react';
import { Helmet } from 'react-helmet';
import Link from '../components/Link/Link';
import { useLocation } from '@reach/router';

import PrimeFavicon from "../assets/icon/favicon.png";

const defaultDescription = 'Edit this in your entry for a better SEO';

const defaultTitle = 'Builder: Drag and Drop Page Building for Any Site';

function PageTemplate({ data }) {
  const models = data?.allBuilderModels;
  const page = models.page[0]?.content;

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{(page && page.data.title) || defaultTitle}</title>
        <link rel="icon" type="image/x-icon" href={PrimeFavicon}></link>
        <meta
          name="description"
          content={
            (page && page.data.description) || defaultDescription
          }
        />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.0, user-scalable=1" />

      <meta property="og:locale" content="en_US" />
      <meta property="og:url" content={useLocation().href} />
      <meta property="og:title" content={page?.data.title} />
      <meta property="og:description" content={page?.data.description} />
      <meta property="og:image" content={page?.data.image} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="Kwickly" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={page?.data.title} />
      <meta name="twitter:image" content={page?.data.image} />

      <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"/>
      <script src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
          async
      ></script>
      </Helmet>
      {/** name of the model is landing page, change it if you decided to build*/}
      <BuilderComponent
        renderLink={Link}
        name="page"
        content={page}
      />
    </>
  );
}

export default PageTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      page(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;
